<template>
  <PanelTableMolecule
    :isLoading="isLoading"
    :count="userCount"
    :title="$tc('user', 2) | capitalize"
    :columns="columns"
    :rows="tableData"
    :pageIndex="pageIndex"
    :pageSize="pageSize"
    :createRouteName="'createUser'"
    :selectedRow="selectedRow"
    :hasClearSearch="search !== undefined"
    @pageNumberChange="pageNumberChange($event)"
    @reload="reload"
    @clear-search="clearSearch"
  />
</template>

<script>
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, CopyIcon, SearchIcon, TrashIcon } from 'vue-feather-icons'
import { debounce } from 'vue-debounce'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'

import USER_COUNT_QUERY from '#/graphql/users/userCount.gql'
import USERS_QUERY from '#/graphql/users/users.gql'

import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

export default {
  components: {
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      userCount: 0,
      users: [],
      errors: [],
      defaultPage: 1,
      defaultPageSize: 10,
      // searchQuery: '',
      tableData: [],
      selectedRow: null,
    }
  },
  watch: {
    rows: {
      handler() {
        this.tableData = this.rows
      },
      immediate: true,
    },
  },
  created() {
    this.triggerSearch = debounce(searchQuery => {
      const search = searchQuery.length > 0 ? searchQuery : undefined
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          page: 1,
          search,
        },
      })
    }, 500)
  },
  computed: {
    isLoading() {
      return this.searchQuery !== '' ? this.loading : this.$apollo.loading
      // return this.$apollo.loading
    },
    pageIndex() {
      const i = parseInt(this.$route?.query.page)
      return !isNaN(i) ? i : this.defaultPage
    },
    pageSize() {
      return this.$route?.query.perPage ?? this.defaultPageSize
    },
    skip() {
      return (this.pageIndex - 1) * this.pageSize
    },
    search() {
      return this.$route?.query?.search?.length > 0 ? this.$route.query.search : undefined
    },
    where() {
      const where = {
        max: this.pageSize,
        first: this.skip,
        search: this.search,
      }
      return where
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasDeletePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'a',
          title: 'Username',
          align: 'left',
          width: '10%',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.search ?? ''}
                  placeholder="Search User"
                  on-valueChange={e => this.triggerSearch(e)}
                  on-cancel={() => this.searchCancel(closeFn, 'name')}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, type, name } = row
            if (!id || !type || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={name} />
          },
        },
        {
          field: 'email',
          key: 'b',
          title: 'Email',
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.search ?? ''}
                  placeholder="Search Email"
                  on-valueChange={e => this.triggerSearch(e)}
                  on-cancel={() => this.searchCancel(closeFn, 'email')}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        { field: 'actions', key: 'c', title: 'Pending Actions', align: 'center', width: '5%' },
        { field: 'roles', key: 'd', title: 'Roles', align: 'center' },
        { field: 'organizations', key: 'e', title: 'Organizations', align: 'center', width: '12%' },
        { field: 'dashboards', key: 'f', title: 'Dashboards', align: 'center' },
        { field: 'assetDimensions', key: 'g', title: 'Asset Dimensions', align: 'center' },
        { field: 'widgets', key: 'h', title: 'Widgets', align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '10%',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <router-link to={`/users/${row?.rowKey}`}>
                  <Edit2Icon size="1.3x" />
                </router-link>
                <router-link to={`/duplicateuser/${row?.rowKey}`}>
                  <CopyIcon size="1.3x" />
                </router-link>
                <button on-click={() => this.deleteRow(row)} disabled={!this.hasDeletePermission}>
                  <TrashIcon size="1.3x" />
                </button>
              </div>
            )
          },
        },
      ]
      return header
    },
    rows() {
      const tableData = this.users.map(user => {
        return {
          email: user.email,
          name: user.username,
          actions: user.requiredActions.length > 0 ? 'Yes' : 'No',
          roles: this.roleMapper(user?.roles?.filter(role => role !== 'offline_access' && role !== 'uma_authorization')),
          organizations: user.memberships ? user.memberships.length : 0,
          dashboards: user.dashboards ? user.dashboards.length : 0,
          assetDimensions: user.assetDimensions ? user.assetDimensions.length : 0,
          widgets: user.widgetTypes ? user.widgetTypes.length : 0,
          rowKey: user.id,
          id: user.id,
          type: user.__typename,
        }
      })
      return tableData
    },
  },
  methods: {
    pageNumberChange(pageIndex) {
      if (pageIndex === this.pageIndex) {
        return
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          page: pageIndex === this.defaultPage ? undefined : pageIndex,
        },
      })
    },
    reload() {
      // this.searchQuery = ''
      // this.searchQuery = ''
      this.$apollo.queries.userCount.refetch()
      this.$apollo.queries.users.refetch()
    },
    roleMapper(roles) {
      return roles.length > 0 ? `${roles}` : 'default'
    },
    clearSearch() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route?.query,
          search: undefined,
          page: undefined,
        },
      })
    },
    searchCancel(closeFn) {
      this.clearSearch()
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    deleteRow(row) {
      this.selectedRow = row
    },
  },
  apollo: {
    userCount: {
      query: USER_COUNT_QUERY,
      variables() {
        return {
          where: {
            search: this.search,
          },
        }
      },
    },
    users: {
      query: USERS_QUERY,
      variables() {
        return {
          where: this.where,
        }
      },
      manual: true,
      result({ data, error }) {
        if (!error) {
          this.users = data?.users ?? []
          this.loading = false
        }
      },
    },
  },
}
</script>
